import { render, staticRenderFns } from "./MyGroupSessions.vue?vue&type=template&id=64574dda&scoped=true&"
import script from "./MyGroupSessions.vue?vue&type=script&lang=js&"
export * from "./MyGroupSessions.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64574dda",
  null
  
)

export default component.exports