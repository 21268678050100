<template>
    <div v-if="myGroupSessions !== null" class="max-w-7xl mx-auto sm:px-6 lg:px-8 my-10">
<!--        <div class="bg-gray-200 flex items-center justify-evenly flex-wrap shadow px-4 py-5 sm:rounded-lg sm:p-6">-->
<!--            <group-session-card v-for="(session) in myGroupSessions.participating" :key="session.JID" @click="openSession" :session="session"></group-session-card>-->
<!--        </div>-->

        <!--
  Tailwind UI components require Tailwind CSS v1.8 and the @tailwindcss/ui plugin.
  Read the documentation to get started: https://tailwindui.com/documentation
-->

        <div v-if="now.length > 0" class="">
            <group-sessions-scroller :my-group-sessions="now">
                <template v-slot:sectionTitle>
                    Now
                </template>
            </group-sessions-scroller>
        </div>

        <div v-if="today.length > 0" class="">
            <group-sessions-scroller :my-group-sessions="today">
                <template v-slot:sectionTitle>
                    Today
                </template>
            </group-sessions-scroller>
        </div>

        <div v-if="nextSevenDays.length > 0" class="">
            <group-sessions-scroller :my-group-sessions="nextSevenDays">
                <template v-slot:sectionTitle>
                    Next 7 Days
                </template>
            </group-sessions-scroller>
        </div>

<!--        <div class="">-->
<!--            <group-sessions-scroller :my-group-sessions="allGroupSessions">-->
<!--                <template v-slot:sectionTitle>-->
<!--                    From your subscriptions-->
<!--                </template>-->
<!--            </group-sessions-scroller>-->
<!--        </div>-->

        <div v-if="moreThanSevenDays.length > 0" class="">
            <group-sessions-scroller :my-group-sessions="moreThanSevenDays">
                <template v-slot:sectionTitle>
                    Future sessions
                </template>
            </group-sessions-scroller>
        </div>

        <div class="">
            <group-sessions-scroller :my-group-sessions="pastSessions">
                <template v-slot:sectionTitle>
                    Past sessions
                </template>
            </group-sessions-scroller>
        </div>
    </div>
</template>

<script>
    import * as _ from 'lodash'
    import {timeDateFromInput, isToday, isInNextSevenDays, isInAboveSevenDays, isInThePast, isSessionNow} from "@/utils/helpers";
    import * as axios from "@/axios-auth"
    import GroupSessionsScroller from "@/components/GroupSessionsScroller";
    export default {
        name: "MyGroupSessions",
        components: {GroupSessionsScroller},
        data(){
            return {
                myGroupSessions: null
            }
        },
        created(){
            this.fetchMyGroupSessions()
        },
        computed:{
            now(){
                return _.filter(this.sortedGroupSessions, (session) => {
                    return isSessionNow(session.groupSession.startsAt)
                })
            },
            today(){
                return _.filter(this.sortedGroupSessions, (session) => {
                    return isToday(session.groupSession.startsAt)
                })
            },
            nextSevenDays(){
                return _.filter(this.sortedGroupSessions, (session) => {
                    return isInNextSevenDays(session.groupSession.startsAt)
                })
            },
            moreThanSevenDays(){
                return _.filter(this.sortedGroupSessions, (session) => {
                    return isInAboveSevenDays(session.groupSession.startsAt)
                })
            },
            pastSessions(){
                return _.filter(this.sortedGroupSessions, (session) => {
                    return isInThePast(session.groupSession.startsAt)
                })
            },
            sortedGroupSessions(){
                return _.orderBy(this.myGroupSessions, ['groupSession.startsAt'], ['asc'])
                // const ordered = _.orderBy(allGroupSessions, ['groupSession.startsAt'], ['asc'])
                // return _.orderBy(ordered, ['status'], ['desc'])
            }
        },
        methods: {
            isSessionNow,
            timeDateFromInput,
            isToday,
            isInNextSevenDays,
            isInAboveSevenDays,
            isInThePast,
            openSession(){
                console.log("Opening Session")
            },
            option1Pressed(){
                console.log("option pressed")
            },
            fetchMyGroupSessions(){
                let loader = this.$loading.show({
                    onCancel: this.onLoaderCancel,
                });
                axios.jigo.get(`/v2/user/myGroupSessions`)
                .then((resp) => {
                    if(resp.status === 200 || resp.status === 201) {
                        this.myGroupSessions = resp.data

                    } else {
                        console.error("ERROR: unwanted status returned", resp.status)
                    }
                    loader.hide()
                })
                .catch((err) => {
                    loader.hide()
                    console.error("ERROR: searchCommunities FAILED", err)
                })
            }
        },
    }
</script>

<style scoped>

</style>
